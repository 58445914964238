<template>
  <div class="page-content" ref="container">
    <div class="card row">
      <div class="card-body col-md-12">
        <b-row>
          <b-col sm="6" md="3" v-for="(step, i) in steps" :key="i" class="my-1">
            <b-button @click="backToStep(i)" class="font-weight-normal text-left" variant="primary" block :disabled="i > currentStep" style="font-size: 14px">
              {{ step }}
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="8">
            <!-- Step 1 -->
            <b-card class="mt-4" v-show="currentStep == 0">
              <b-card-title class="text-capitalize">Sender</b-card-title>
              <b-card-sub-title class="mb-4">
                Choose what channel you want to send broadcast.
              </b-card-sub-title>
              <el-select @change="handlerChangeChannel" placeholder="Select channel" class="w-100 rounded" v-model="form.channel_id">
                <el-option :value="item.value" v-for="item in channelList" :key="item.value" :label="item.text"/>
              </el-select>
              <hr/>
              <b-card-title class="text-capitalize">Recipient Type</b-card-title>
              <b-card-sub-title class="mb-4">
                Choose what source of recipients to broadcast.
              </b-card-sub-title>
              <el-select @change="handlerChangeRecipientType" placeholder="Select channel" class="w-100 rounded" v-model="form.recipient_type">
                <el-option :value="item.value" v-for="item in recipientTypeList" :key="item.value" :label="item.text"/>
              </el-select>
              <hr/>
              <span v-show="form.recipient_type == 'csv'">
                <b-card-title class="text-capitalize">Upload CSV</b-card-title>
                <b-card-sub-title class="mb-4">
                  Upload your contact recipient with csv file. Make sure you have include column<code>phone</code>in your csv. You can download the csv template <a href="#" @click="downloadCsv()">here</a>.
                </b-card-sub-title>
                <input type="file" class="dropify" accept=".csv" v-on:input="handlerInputRecipientFile"/>
              </span>
              <span v-show="form.recipient_type == 'label'">
                <b-card-title class="text-capitalize">Choose Label</b-card-title>
                <b-card-sub-title class="mb-4">
                  Choose source of label which you want to send.
                </b-card-sub-title>
                <el-select multiple filterable :multiple-limit="3" placeholder="Select label. Max 3 labels" class="w-100 rounded" v-model="form.labels_id">
                  <el-option :key="item.id" :value="item.id" :label="item.title" v-for="item in labelList"/>
                </el-select>
              </span>
              <span v-show="form.recipient_type == 'group'">
                <b-card-title class="text-capitalize">Choose Group</b-card-title>
                <b-card-sub-title class="mb-4">
                  Choose source of group which you want to send.
                </b-card-sub-title>
                <el-select filterable :filter-method="handlerSearchGroup" placeholder="Select group" class="w-100 rounded" v-model="form.group_id">
                  <el-option :key="item.id" :value="item.id" :label="item.name" v-for="item in groupList"/>
                </el-select>
              </span>
              <hr/>
              <div class="d-flex float-right mb-4">
                <el-button :loading="loading.step" @click="nextStep(currentStep)" :disabled="!isValidStep1" size="small" type="primary">Continue</el-button>
              </div>
            </b-card>
            <!-- Step 2 -->
            <div v-show="currentStep == 1">
              <b-card class="mt-4">
                <b-card-title class="text-capitalize">Batch Name</b-card-title>
                <b-card-sub-title class="mb-4">Input broadcast batch name to help you recognize broadcast segmentation.</b-card-sub-title>
                <el-input ref="inputBatchName" v-model="form.batch_name" placeholder="Input batch name"/>
                <hr/>
                <b-card-title class="text-capitalize">Template Name</b-card-title>
                <b-card-sub-title class="mb-4">Select template you want to use.</b-card-sub-title>
                <el-select placeholder="Select template" v-model="form.template_id" @change="handlerChangeTemplate" filterable class="w-100">
                  <el-option :value="item.id" v-for="(item, i) in templateList" :key="i" :label="item.template_name_str"/>
                </el-select>
                <!-- Template Header Media Variable -->
                <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
                  <hr/>
                  <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
                  <b-card-sub-title class="mb-4">Input file media to send.</b-card-sub-title>
                  <b-row v-if="selected_template.component_header.format.toLowerCase() !== 'text'">
                    <b-col md="3">
                      <el-select @change="handlerHeaderMediaChange" v-model="form.header_params.input_type">
                        <el-option value="file" label="File input"/>
                        <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                        <el-option value="url" label="URL input"/>
                      </el-select>
                    </b-col>
                    <b-col md="9" v-if="['csv', 'url'].includes(form.header_params.input_type)">
                      <el-input v-model="form.header_params[form.header_params.type]"/>
                      <small class="text-danger" v-if="form.header_params.input_type === 'url' && !isValidHeaderMediaUrl">URL not valid</small>
                    </b-col>
                    <b-col md="9" v-if="form.header_params.input_type == 'file'">
                      <b-file :accept="whiteListExtensionHeaderMedia[selected_template.component_header.format.toLowerCase()].join(',')" @change="handlerFileHeaderMedia" v-model="template_header.file"/>
                    </b-col>
                  </b-row>
                </div>
                <!-- End of Template Header Media Variable -->
                <!-- Template Header Text Variable -->
                <div v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() == 'text' && selected_template.component_header.header_params.param.length > 0">
                  <hr/>
                  <b-card-title class="text-capitalize">Template Variables (Header)</b-card-title>
                  <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
                  <b-row>
                    <b-col md="3">
                      <el-select v-model="form.header_params.input_type">
                        <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                        <el-option value="text" label="Static Text"/>
                      </el-select>
                    </b-col>
                    <b-col md="9">
                      <el-input v-model="form.header_params.text" placeholder="Input parameter value"/>
                    </b-col>
                  </b-row>
                </div>
                <!-- End of Template Header Text Variable -->
                <!-- Template Body Variable -->
                <div v-if="form.body_params.length > 0">
                  <hr/>
                  <b-card-title class="text-capitalize">Template Variables (Body)</b-card-title>
                  <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
                  <b-row class="mb-2" v-for="(item, i) in selected_template.body_params" :key="i">
                    <b-col md="3">
                      <el-select v-model="form.body_params[i].type">
                        <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                        <el-option v-if="form.recipient_type === 'group'" value="contact_field" label="Contact Field"/>
                        <el-option value="text" label="Static Text"/>
                      </el-select>
                    </b-col>
                    <b-col md="9">
                      <el-select v-if="form.body_params[i].type == 'contact_field' && form.recipient_type == 'group'" v-model="form.body_params[i].text">
                        <el-option value="display_name" label="Name"></el-option>
                        <el-option value="phone_number" label="Phone Number"></el-option>
                      </el-select>
                      <el-input v-else v-model="form.body_params[i].text" placeholder="Input parameter value"/>
                    </b-col>
                  </b-row>
                </div>
                <!-- End of Template Body Variable -->
                <!-- Template Button Variable -->
                <div v-if="form.cta_params.length > 0">
                  <hr/>
                  <b-card-title class="text-capitalize">Template Variables (Button)</b-card-title>
                  <b-card-sub-title class="mb-4">Input all variable needed.</b-card-sub-title>
                  <b-row class="mb-2" v-for="(item, i) in form.cta_params" :key="i">
                    <b-col md="3">
                      <el-select v-model="form.cta_params[i].type">
                        <el-option v-if="form.recipient_type === 'csv'" value="csv" label="CSV Column"/>
                        <el-option value="text" label="Static Text"/>
                      </el-select>
                    </b-col>
                    <b-col md="9">
                      <el-input v-model="form.cta_params[i].text" placeholder="Input parameter value"/>
                    </b-col>
                  </b-row>
                </div>
                <!-- End of Template Button Variable -->
                <hr/>
                <span v-if="form.recipient_type === 'csv'">
                  <b-card-title class="text-capitalize">Mandatory CSV Column</b-card-title>
                  <b-card-sub-title class="mb-4">These are mandatory column you need on your csv.</b-card-sub-title>
                  <div class="w-25">
                    <div class="list-group">
                      <span v-for="(item, i) in mandatoryHeader" :key="i">
                        <div class="list-group-item" v-if="item.value">
                          <p class="d-flex justify-content-between align-items-center">
                            {{ item.value }}
                            <el-popover
                              placement="right"
                              width="325"
                              trigger="hover">
                              <p v-if="item.isValid === true" style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">Good job! This column exists on your csv.</p>
                              <p v-else-if="item.isValid === false" style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This column is required on your csv but not found on your csv. Please fix your csv.</p>
                              <p v-else style="white-space: pre-line; overflow-wrap: break-word; word-break: break-all;">This column is required on your csv.</p>
                              <span slot="reference" class="float-right">
                                <font-awesome v-if="item.isValid === true" icon="check-circle" class="text-success float-right"/>
                                <font-awesome v-else-if="item.isValid === false" icon="exclamation-circle" class="text-danger float-right"/>
                                <font-awesome v-else icon="question-circle" class="text-info float-right"/>
                              </span>
                            </el-popover>
                          </p>
                        </div>
                      </span>
                    </div>
                  </div>
                  <hr/>
                </span>
                <div class="d-flex float-right mb-4">
                  <el-button :loading="loading.step" @click="nextStep(currentStep)" :disabled="!isValidStep2" size="small" type="primary">Continue</el-button>
                  <el-button :loading="loading.step" @click="backToStep(currentStep - 1)" size="small">Back</el-button>
                </div>
              </b-card>
            </div>
            <!-- Step 3 -->
            <div v-show="currentStep == 2">
              <b-card class="mt-4">
                <b-card-title class="text-capitalize">Send or Schedule</b-card-title>
                <b-card-sub-title class="mb-4">
                  Choose when you want to send broadcast message.
                </b-card-sub-title>
                <b-row>
                  <b-col md=12>
                    <el-radio-group v-model="form.send_time">
                      <el-radio-button value="now" label="now">Now</el-radio-button>
                      <el-radio-button valuue="scheduled" label="scheduled">Later with Schedule</el-radio-button>
                    </el-radio-group>
                  </b-col>
                </b-row>
                <template v-if="form.send_time === 'scheduled'" class="mt-2">
                  <hr/>
                  <b-card-title class="text-capitalize">Set Schedule</b-card-title>
                  <b-card-sub-title class="mb-4">
                    Choose date and time you want to send the messages.
                  </b-card-sub-title>
                  <b-row>
                    <b-col md=4>
                      <el-date-picker
                        v-model="form.schedule_date_day"
                        type="date"
                        :picker-options="date_picker_options"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        placeholder="Select schedule date"/>
                    </b-col>
                    <b-col md=4>
                      <el-time-select
                        v-model="form.schedule_date_time"
                        :picker-options="time_picker_options"
                        placeholder="Select schedule time"/>
                    </b-col>
                  </b-row>
                </template>
              </b-card>
              <hr/>
              <div class="d-flex float-right mb-4">
                <el-button @click="nextStep(currentStep)" :disabled="!isValidStep3" size="small" type="primary">Continue</el-button>
                <el-button @click="backToStep(currentStep - 1)" size="small">Back</el-button>
              </div>
            </div>
            <!-- STEP 4 -->
            <div v-show="currentStep == 3">
              <b-card class="mt-4">
                <b-card-title class="text-capitalize">Confirmation</b-card-title>
                <b-card-sub-title class="mb-4">
                  This is the last step. Maybe some checking would help before you continue. If there's any change, feel free to back to change it.
                </b-card-sub-title>
                <table class="w-100">
                  <tr style="height: 35px">
                    <td width="30%"><strong>Channel</strong></td>
                    <td>{{ selected_channel.text || "Unknown Channel" }}</td>
                  </tr>
                  <tr style="height: 35px">
                    <td width="20%"><strong>Template Name</strong></td>
                    <td>{{ form.template_name }}</td>
                  </tr>
                  <tr style="height: 35px">
                    <td width="20%"><strong>Recipient</strong></td>
                    <td>{{ uploaded_recipient.count }}</td>
                  </tr>
                  <tr style="height: 35px">
                    <td width="20%"><strong>Send Time</strong></td>
                    <td>{{ form.send_time == 'now' ? 'Now' : moment(form.schedule_date).format('DD MMM YYYY, HH:mm') }}</td>
                  </tr>
                  <tr style="height: 35px">
                    <td width="20%"><strong>Balance</strong></td>
                    <td>Rp. {{ uploaded_recipient.balance_amount.toLocaleString() }}</td>
                  </tr>
                  <tr style="height: 35px" v-if="selected_channel.type == 'whatsapp' && uploaded_recipient.free_quota_whatsapp">
                    <td width="20%"><strong>Free WhatsApp Quota</strong></td>
                    <td>{{ uploaded_recipient.free_quota_whatsapp.toLocaleString() }} conversation(s)</td>
                  </tr>
                  <tr style="height: 35px" v-if="selected_channel.type == 'whatsapp' && uploaded_recipient.free_business_initiated">
                    <td width="20%"><strong>Free WhatsApp BI Quota</strong></td>
                    <td>{{ uploaded_recipient.free_business_initiated.toLocaleString() }} conversation(s)</td>
                  </tr>
                  <tr style="height: 35px">
                    <td width="20%"><strong>Estimated Cost</strong></td>
                    <td :class="{ 'text-danger font-weight-bold': uploaded_recipient.estimated_amount > uploaded_recipient.balance_amount }">Rp. {{ uploaded_recipient.estimated_amount.toLocaleString() }}</td>
                  </tr>
                </table>
              </b-card>
              <hr/>
              <div class="d-flex float-right mb-4">
                <el-button @click="sendNotification" size="small" type="primary">Submit</el-button>
                <el-button @click="backToStep(currentStep - 1)" size="small">Back</el-button>
              </div>
            </div>
          </b-col>
          <b-col sm="4">
            <div class="screen mt-4">
              <div class="screen-container">
                <div class="chat">
                  <div class="chat-container">
                    <div class="conversation">
                      <div class="conversation-container vh-100">
                        <h6 class="mt-2">Preview</h6>
                        <div class="preview-message received w-100">
                          <span v-if="previewHeaderContent" style="font-size: 14px; font-weight: bold">{{previewHeaderContent}}<br/></span>
                          <div class="header-media-area" v-if="selected_template.component_header && selected_template.component_header.format.toLowerCase() !== 'text'">
                            <div v-if="!template_header.base64" class="header-media-content" :style="'background-image: url('+ bg_media[selected_template.component_header.format.toLowerCase()] +')'"/>
                            <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'image'" class="header-media-filled" :style="'background-image: url('+ template_header.base64 +')'"/>
                            <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'video'">
                              <iframe width="240" :src="template_header.base64"/>
                            </div>
                            <div v-else-if="template_header.base64 && selected_template.component_header.format.toLowerCase() == 'document'" class="header-media-content" :style="'background-image: url('+ bg_media.document +')'"/>
                          </div>
                          <p class="preview-body mt-2" v-html="previewContent"/>
                          <p class="mt-2 preview-footer" v-if="selected_template.component_footer">{{selected_template.component_footer.text}}<br/></p>
                          <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                          <div class="cta-area w-100" v-if="selected_template.component_buttons && selected_template.component_buttons.buttons[0].type.toLowerCase() !== 'quick_reply'">
                            <div class="cta-button" v-for="(item, i) in selected_template.component_buttons.buttons" :key="i">
                              <p><font-awesome :icon="item.type.toLowerCase() === 'url' ? 'external-link-alt' : 'phone-alt'"/>&nbsp;{{ item.text }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="button-area w-100" style="margin-top: -1em" v-if="selected_template.component_buttons && selected_template.component_buttons.buttons[0].type.toLowerCase() === 'quick_reply'">
                          <div class="row-button">
                            <div class="col-button" v-for="(item, i) in selected_template.component_buttons.buttons" :key="i">
                              {{ item.text }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  isEmpty, escapeRegExp, uniq,
} from 'lodash';
import useVuelidate from '@vuelidate/core';
import axios from 'axios';
// import { required } from '@vuelidate/validators';
import moment from 'moment';
// import mime from 'mime';
import popupErrorMessages from '@/library/popup-error-messages';
import channelsAPI from '@/api/channels';
import notificationAPI from '@/api/notifications';
import base64File from '@/library/base64File';
import labelsAPI from '@/api/labels';
import groupsAPI from '@/api/groups';

export default {
  name: 'Notifications',
  metaInfo: {
    title: 'Notifications',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      moment,
      currentStep: 0,
      steps: [
        '1. Channel & Recipients',
        '2. Message',
        '3. Send Time',
        '4. Confirmation',
      ],
      selected_file: {
        recipient: '',
      },
      bg_media: {
        image: 'https://api.media.makna.ai/makna-media/template-header-image.png',
        document: 'https://api.media.makna.ai/makna-media/template-header-document.png',
        video: 'https://api.media.makna.ai/makna-media/template-header-video.png',
      },
      loaderStack: 0,
      loader: null,
      templateList: [],
      channelList: [],
      recipentList: [],
      showModalNotification: false,
      search: '',
      page: 1,
      totalRow: 0,
      form: {
        // recipient: '7cc44a6b-16ad-4e4f-aa17-84c221666dbf',
        recipient: '',
        recipient_type: 'csv',
        batch_name: '',
        template_name: '',
        template_language: '',
        channel_id: '',
        body_params: [],
        header_params: {},
        cta_params: [],
        labels_id: [],
        group_id: '',
        send_time: 'now',
        schedule_date: '',
        schedule_date_day: '',
        schedule_date_time: '',
      },
      uploaded_recipient: {
        balance_amount: 0,
        estimated_amount: 0,
        free_quota_whatsapp: 0,
      },
      accepted_ext: {
        document: '.csv, .xls',
      },
      drEvent: {},
      selected_template: {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
      },
      template_header: {
        file: null,
        base64: '',
      },
      recipient_file: null,
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
      loading: {
        step: false,
      },
      valid_param: null,
      missing_param_csv: null,
      recipientTypeList: [
        {
          value: 'csv',
          text: 'CSV',
        },
        {
          value: 'label',
          text: 'Label on Contacts',
        },
        {
          value: 'group',
          text: 'Group on Contacts',
        },
      ],
      labelList: [],
      groupList: [],
      selected_channel: {},
      date_picker_options: {
        disabledDate: (time) => moment().subtract(1, 'day').isSameOrAfter(moment(time), 'day'),
      },
    };
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.page = val;
      },
    },
    workspaces() {
      return this.$store.state.workspace.workspaces.map((workspace) => ({
        text: workspace.name,
        value: workspace._id,
      }));
    },
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    isValidHeaderMediaUrl() {
      /* eslint-disable no-useless-escape */
      const regex_url = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/ig;
      const { type, input_type } = this.form.header_params;
      if (input_type === 'url') {
        return regex_url.test(this.form.header_params[type]);
      }
      return true;
    },
    isValidStep1() {
      if (this.form.recipient_type === 'csv') {
        return this.recipient_file && this.form.channel_id;
      }
      if (this.form.recipient_type === 'label') {
        return this.form.labels_id.length > 0 && this.form.channel_id;
      }
      if (this.form.recipient_type === 'group') {
        return this.form.group_id && this.form.channel_id;
      }
      return false;
    },
    isValidStep2() {
      let is_body_valid = true;
      let is_header_valid = true;
      let is_button_valid = true;
      if (this.form.body_params.length > 0) {
        const findEmpty = this.form.body_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_body_valid = false;
        }
      }
      if (this.form.header_params?.type) {
        const { type, input_type } = this.form.header_params;
        if (input_type === 'file' && !this.template_header.file) {
          is_header_valid = false;
        } else if (type === 'text' && !this.form.header_params[type] && this.selected_template.component_header?.header_params?.param?.length > 0) {
          is_header_valid = false;
        }
        if (!this.isValidHeaderMediaUrl) {
          is_header_valid = false;
        }
      }

      if (this.form.cta_params.length > 0) {
        const findEmpty = this.form.cta_params.filter((v) => !v.text);
        if (findEmpty.length) {
          is_button_valid = false;
        }
      }
      return is_header_valid && is_body_valid && is_button_valid && this.form.template_id && this.form.batch_name;
    },
    isValidStep3() {
      if (this.form.send_time === 'now') {
        return true;
      }
      return this.form.send_time === 'scheduled' && !isEmpty(this.form.schedule_date_day) && !isEmpty(this.form.schedule_date_time);
    },
    time_picker_options() {
      const opt = {
        start: moment().add(1, 'hour').startOf('hour').format('HH:mm'),
        end: moment().endOf('day').format('HH:mm'),
        step: '00:60',
      };

      if (moment(this.form.schedule_date_day).isAfter(moment(), 'day')) {
        opt.start = moment().startOf('day').format('HH:mm');
      }
      return opt;
    },
    previewHeaderContent() {
      if (this.selected_template.component_header?.format.toLowerCase() === 'text') {
        if (this.form.header_params.text) {
          return this.selected_template.component_header?.text.replace('{{1}}', this.form.header_params.text);
        }
        return this.selected_template.component_header?.text;
      }
      return '';
    },
    previewContent() {
      if (this.selected_template.component_body) {
        let string = this.selected_template.component_body.text;
        const htmlFormat = [
          { symbol: '*', tag: 'b' },
          { symbol: '_', tag: 'em' },
          { symbol: '~', tag: 'del' },
          { symbol: '```', tag: 'code' },
        ];

        this.selected_template.body_params.forEach((v, i) => {
          const replaceRegex = new RegExp(escapeRegExp(v), 'ig');
          const replace_text = this.form.body_params[i].text;
          if (replace_text) {
            string = string.replace(replaceRegex, replace_text);
          }
        });

        htmlFormat.forEach(({ symbol, tag }) => {
          if (!string) return;
          const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
          const match = string.match(regex);
          if (!match) return;
          match.forEach((m) => {
            let formatted = m;
            /* eslint-disable no-plusplus */
            for (let i = 0; i < 2; i++) {
              formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
            }
            string = string.replace(m, formatted);
          });
        });
        return string;
      }
      return '';
    },
    mandatoryHeader() {
      let mandatory_header = ['phone'];
      if (this.form.header_params && this.form.header_params.input_type === 'csv') {
        mandatory_header.push(this.form.header_params[this.form.header_params.type]);
      }
      if (this.form.body_params.length > 0) {
        const column = this.form.body_params.filter((v) => v.type === 'csv').map((v) => v.text);
        mandatory_header.push(...column);
      }
      if (this.form.cta_params.length > 0) {
        const url_btn = this.form.cta_params.find((v) => v.button === 'url');
        if (url_btn && url_btn.type === 'csv') {
          mandatory_header.push(url_btn.text);
        }
      }
      mandatory_header = uniq(mandatory_header);
      return mandatory_header.map((v) => {
        const obj = {
          value: v,
          isValid: null,
        };
        if (this.valid_param) {
          /* eslint-disable no-unneeded-ternary */
          const value_in_valid = this.valid_param.find((k) => k.value === v);
          obj.isValid = value_in_valid ? true : false;
          if (!obj.isValid && this.missing_param_csv && !this.missing_param_csv.includes(v)) {
            obj.isValid = null;
          }
        }
        return obj;
      });
    },
  },
  async mounted() {
    this.showLoader();
    await this.getChannelList();
    this.$nextTick(() => {
      /* eslint-disable no-undef */
      this.drEvent = $('.dropify').dropify({
        messages: {
          default: 'Drag and drop a file here or click',
          replace: 'Drag and drop or click to replace',
          remove: 'Remove',
          error: 'Ooops, something wrong happended.',
        },
      });
    });

    // this.nextStep(0);
    // await this.getTemplate();

    this.hideLoader();
  },
  methods: {
    handlerChangeChannel(value) {
      this.selected_channel = this.channelList.find((v) => v.value === value);
    },
    async nextStep(step) {
      this.loading.step = true;
      if (step === 0) {
        this.showLoader();
        if (this.templateList.length === 0) {
          await this.getTemplate();
        }
        if (document.getElementsByClassName('dropify-wrapper')[0]) {
          document.getElementsByClassName('dropify-wrapper')[0].style.display = 'none';
        }
        this.hideLoader();
      } else if (this.currentStep === 1) {
        if (this.form.header_params && this.form.header_params.input_type === 'url') {
          this.showLoader();
          const is_valid = await this.validateContentTypeMediaURL();
          this.hideLoader();
          if (!is_valid) {
            this.$message({
              type: 'warning',
              message: 'Media URL is not valid',
            });
            return;
          }
          this.loading.step = false;
        }
        if (['csv', 'label', 'group'].includes(this.form.recipient_type)) {
          try {
            const response = await this.uploadRecipient();
            this.loading.step = false;
            // console.log('response', response.error);
            if (response.error) {
              if (response.error.code === 1001) {
                this.missing_param_csv = response.data.missing;
                if (this.missing_param_csv) {
                  this.valid_param = this.mandatoryHeader.filter((v) => !this.missing_param_csv.includes(v.value));
                  this.$message({
                    type: 'warning',
                    message: 'Ops! Some field on your csv is missing.',
                  });
                  return;
                }
              }
              await popupErrorMessages(response);
              return;
            }
            this.missing_param_csv = [];
            this.valid_param = this.mandatoryHeader.filter((v) => !this.missing_param_csv.includes(v.value));
            if (this.form.header_params.type !== 'text') {
              if (this.form.header_params.input_type === 'file') {
                this.form.header_params[this.form.header_params.type] = this.template_header.base64;
              }
              if (this.form.header_params.type === 'document' && this.form.header_params.input_type === 'file') this.form.header_params.filename = this.template_header.file.name;
            }
            this.form.recipient = response.data.id;
            this.uploaded_recipient = response.data;
          } catch (error) {
            if (error) console.log(error);
            return;
          }
        }
      }
      this.form.schedule_date = moment([this.form.schedule_date_day, this.form.schedule_date_time].join(' ')).format('YYYY-MM-DD HH:mm:00');
      this.loading.step = false;
      this.currentStep = step + 1;

      if (this.currentStep === 1) {
        this.$refs.inputBatchName.focus();
      }
    },
    async addNotification() {
      this.showModalNotification = true;
      this.showLoader();
      await this.getChannelList();
      await this.getTemplate();
      this.form.workspace_id = this.activeWorkspace._id;
      this.hideLoader();
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    async getTemplate() {
      await notificationAPI.getTemplateList(this.activeWorkspace._id, this.form.channel_id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.templateList = res.data.templates.filter((v) => v.status?.toLowerCase() === 'approved').map((v) => {
            v.template_name_str = `${v.name} (${v.language})`;
            return v;
          });
        })
        .catch(() => {});
    },
    async getChannelList() {
      const channel_name_obj = {
        whatsapp: 'WhatsApp',
        sms: 'SMS',
        instagram: 'Instagram',
        telegram: 'Telegram',
      };
      await channelsAPI.getList(this.activeWorkspace._id)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.channelList = res.data.channels.map((channel) => ({
            text: channel_name_obj[channel.type],
            type: channel.type,
            value: channel._id,
            status: channel.status,
          }));
          this.selected_channel = this.channelList.find((v) => v.status === 'active');
          this.form.channel_id = this.selected_channel.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearForm() {
      this.recipentList = [];
      this.form = {
        idfile: '',
        phone: '',
        name: '',
        batch: '',
        template: '',
        channel_id: '',
        workspace_id: '',
        file: null,
        type: 'document',
        mandatory_header: 'name',
        labels_id: [],
      };
    },
    closeModalNotification() {
      this.showModalNotification = false;
    },
    async handlerInputRecipientFile() {
      const target_file = this.drEvent[0].files[0];
      this.recipient_file = target_file;
    },
    async uploadRecipient() {
      this.showLoader();
      const target_file = this.recipient_file;
      const formData = new FormData();
      formData.append('recipient_type', this.form.recipient_type);
      /* eslint-disable prefer-object-spread */
      const template = Object.assign({}, this.selected_template);
      delete template.template_groups;
      formData.append('template', JSON.stringify(this.selected_template));
      if (this.form.recipient_type === 'csv') {
        formData.append('file', target_file);
      } else if (this.form.recipient_type === 'label') {
        this.form.labels_id.forEach((val) => {
          formData.append('labels_id[]', val);
        });
      } else if (this.form.recipient_type === 'group') {
        formData.append('group_id', this.form.group_id);
      }
      const mandatory_header = ['phone'];
      if (this.form.header_params && this.form.header_params.input_type === 'csv') {
        mandatory_header.push(this.form.header_params[this.form.header_params.type]);
      }
      if (this.form.body_params.length > 0) {
        const column = this.form.body_params.filter((v) => v.type === 'csv').map((v) => v.text);
        mandatory_header.push(column);
      }
      if (this.form.cta_params.length > 0) {
        const url_btn = this.form.cta_params.find((v) => v.button === 'url');
        if (url_btn && url_btn.type === 'csv') {
          mandatory_header.push(url_btn.text);
        }
      }
      formData.append('mandatory_header', mandatory_header.join(','));
      const response = await notificationAPI.uploadRecipent(formData, this.activeWorkspace._id, this.form.channel_id)
        .catch(() => {});
      this.hideLoader();
      return response;
    },
    async sendNotification() {
      this.$confirm(this.$t('notification.confirm_send'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const response = await notificationAPI.sendBroadcast({
              workspace_id: this.activeWorkspace._id,
              form: this.form,
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            await popupErrorMessages(response);
            // console.log(response);
            const errormsg = response.error;
            if (isEmpty(errormsg)) {
              this.$message({
                type: 'success',
                message: this.$t('notification.success'),
                duration: 30 * 1000,
                showClose: true,
              });
              this.resetAll();
              cb();
            } else {
              this.$message({
                title: this.$t('general.error.general'),
                type: 'error',
                message: response.error,
                duration: 30 * 1000,
                showClose: true,
              });
            }
          }
          cb();
        },
      });
    },
    backToStep(i) {
      this.currentStep = i;
      if (i === 0) {
        if (document.getElementsByClassName('dropify-wrapper')[0]) {
          document.getElementsByClassName('dropify-wrapper')[0].style.display = 'block';
        }
      }
      if (i === 1) {
        this.missing_param_csv = [];
      }
    },
    handlerChangeTemplate(value) {
      this.template_header = {
        file: null,
        base64: '',
      };
      this.form.header_params = {};
      this.form.body_params = [];
      this.form.cta_params = [];
      this.selected_template = this.templateList.find((v) => v.id === value);
      this.form.template_name = this.selected_template.name;
      this.form.template_language = this.selected_template.language;
      const component_body = this.selected_template.components.find((v) => v.type.toLowerCase() === 'body');
      const component_header = this.selected_template.components.find((v) => v.type.toLowerCase() === 'header');
      if (component_header) {
        const format = component_header?.format?.toLowerCase();
        this.form.header_params = {
          input_type: 'file',
          [format]: '',
          type: format,
        };
        component_header.header_params = {};
      }
      if (component_header?.format?.toLowerCase() === 'text') {
        this.form.header_params = {
          input_type: 'text',
          type: 'text',
          text: '',
        };
        component_header.header_params = {
          type: 'text',
          param: component_header.text.match(/({{\d{1,}}})/ig) || [],
        };
      }
      const component_footer = this.selected_template.components.find((v) => v.type.toLowerCase() === 'footer');
      const component_buttons = this.selected_template.components.find((v) => v.type.toLowerCase() === 'buttons');
      if (component_buttons) {
        const btn_url = component_buttons.buttons.find((v) => v.type.toLowerCase() === 'url');
        if (btn_url) {
          const has_dynamic = btn_url.url.match(/({{\d{1,}}})/ig);
          if (has_dynamic) {
            this.form.cta_params = [
              {
                button: 'url',
                type: 'text',
                text: '',
              },
            ];
          }
        }
      }
      const body_params = uniq(component_body.text.match(/({{\d{1,}}})/ig)) || [];
      if (body_params) this.form.body_params = body_params.map(() => ({ type: 'text', text: '' }));
      /* eslint-disable prefer-object-spread */
      this.selected_template = Object.assign({
        component_body,
        body_params,
        component_header,
        component_footer,
        component_buttons,
      }, this.selected_template);
    },
    async handlerFileHeaderMedia(event) {
      const file = event.target.files[0];
      if (file) {
        this.template_header.base64 = await base64File(file);
      }
    },
    handlerHeaderMediaChange() {
      this.template_header = {
        base64: '',
        file: null,
      };
    },
    async validateContentTypeMediaURL() {
      const { type } = this.form.header_params;
      let valid_content_type = ['image/jpeg', 'image/jpg', 'image/png'];
      if (type === 'video') {
        valid_content_type = ['video/mp4', 'video/3gpp'];
      } else if (type === 'document') {
        return true;
      }
      try {
        const response = await axios({
          url: this.form.header_params[type],
          responseType: 'arraybuffer',
          responseEncoding: 'binary',
        });
        const isValid = valid_content_type.includes(response.headers['content-type'].split(';')[0]);
        if (isValid) {
          this.template_header.base64 = this.form.header_params[type];
        }
        return isValid;
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    downloadCsv() {
      const url = `${window.location.origin}/template/template-recipient.csv`;
      const label = 'template-recipient.csv';
      axios.get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = label;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetAll() {
      this.currentStep = 0;
      this.selected_file = {
        recipient: '',
      };
      this.form = {
        recipient: '',
        recipient_type: 'csv',
        batch_name: '',
        template_name: '',
        template_language: '',
        channel_id: '',
        body_params: [],
        header_params: {},
        cta_params: [],
        send_time: 'now',
      };
      this.valid_param = null;
      this.missing_param_csv = null;
      this.templateList = [];
      this.template_header = {
        file: null,
        base64: '',
      };
      this.recipient_file = null;
      this.selected_template = {
        component_header: null,
        component_body: null,
        component_footer: null,
        component_buttons: null,
      };
      if (document.getElementsByClassName('dropify-wrapper')[0]) {
        document.getElementsByClassName('dropify-wrapper')[0].style.display = 'block';
      }
      $('.dropify-clear').click();
    },
    handlerChangeRecipientType(value) {
      if (value === 'csv') {
        document.getElementsByClassName('dropify-wrapper')[0].style.display = 'block';
      } else {
        document.getElementsByClassName('dropify-wrapper')[0].style.display = 'none';
      }

      if (value === 'label') {
        this.getLabelList();
      }

      if (value === 'group') {
        this.getGroupList('');
      }
    },
    async getLabelList() {
      this.showLoader();
      await labelsAPI.getList(this.activeWorkspace._id, 1, null)
        .then(async (res) => {
          await popupErrorMessages(res);
          this.labelList = res.data.rows;
        })
        .catch(() => {});
      this.hideLoader();
    },
    async getGroupList(value) {
      this.showLoader();
      await groupsAPI.getList({
        workspace_id: this.activeWorkspace._id,
        page: 1,
        search: value,
        search_by: 'name',
      })
        .then(async (res) => {
          await popupErrorMessages(res);
          this.groupList = res.data.rows;
        })
        .catch(() => {});
      this.hideLoader();
    },
    async handlerSearchGroup(value) {
      clearTimeout(this.timeout_search_user);
      this.timeout_search_user = setTimeout(() => {
        this.getGroupList(value);
      }, 1000);
    },
  },
};
</script>
<style>
.dropify-wrapper .dropify-message p {
  font-size: 16px;
  color: #686868;
}
.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.preview-message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 265px;
  word-wrap: break-word;
  z-index: -1;
}

.preview-message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  max-width: 265px;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 4px;
  box-sizing: border-box;
  width: 240px;
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://api.media.makna.ai/makna-media/template-header-image.png');
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.preview-message:first-child {
  margin: 16px 0 8px;
}

.preview-message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.preview-message.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.preview-message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}

/* Small Screens */

@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
}
</style>
